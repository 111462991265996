import { ref, Ref } from "vue";

function createInitializableStore() {
  const initializationFailed: Ref<boolean> = ref(false);
  const initializationPromise: Ref<Promise<void> | null> = ref(null);
  const initialized: Ref<boolean> = ref(false);
  const loading: Ref<boolean> = ref(false);

  /**
   * This can be called multiple times, but the initialization will only happen once,
   * and even if called while executing, it will wait for the current initialization to finish.
   * @param force - Forces fetching data again, even if it was already fetched.
   */
  async function baseInitialize(
    internalInit: (options?: any) => Promise<void>,
    force = false,
    options?: any,
  ): Promise<void> {
    if (!force && initialized.value) {
      return Promise.resolve();
    }

    if (force || !initializationPromise.value || initializationFailed.value) {
      initializationPromise.value = baseInternalInit(internalInit, options);
    }

    return initializationPromise.value;
  }

  async function baseInternalInit(
    internalInit: (options?: any) => Promise<void>,
    options?: any,
  ): Promise<void> {
    try {
      initialized.value = false;
      loading.value = true;

      await internalInit(options);

      initialized.value = true;
      initializationFailed.value = false;
    } catch (error) {
      console.error(error);
      initializationFailed.value = true;
      throw error;
    } finally {
      loading.value = false;
    }
  }

  return {
    initializationFailed,
    initializationPromise,
    initialized,
    loading,
    baseInitialize,
  };
}

export default function useInitializableStore() {
  return createInitializableStore();
}
