<script setup lang="ts">
import { useAppStore } from "@/store/app/appStore";
import { useAuthStore } from "@/store/auth/authStore";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import LoadingOverlayVue from "@/components/LoadingOverlay.vue";
import { trackPageView } from "@/services/tracking";
import useToasts from "@/composables/useToasts";

const { t } = useI18n();
const appStore = useAppStore();
const authStore = useAuthStore();
const router = useRouter();
const toasts = useToasts();

router.beforeEach(async (to) => {
  try {
    if (to.name === "unavailable") {
      return;
    }
    await appStore.initialize();
  } catch (error) {
    console.error("Error while initializing the app.", error);
    toasts.addToastError(t("app.error.appInitError"));
    return { name: "unavailable" };
  }

  if (to.meta.requiresAuth) {
    if (!authStore.isAuthenticated) {
      return { name: "welcome" };
    }
  }

  if (authStore.isAuthenticated) {
    trackPageView(to);
  }
});

router.afterEach(() => {
  authStore.updateTheme();
});
</script>

<template>
  <RouterView v-slot="{ Component }">
    <Transition name="fade">
      <component :is="Component" class="text-[#252525] dark:text-white" />
    </Transition>
  </RouterView>
  <LoadingOverlayVue v-if="appStore.loading" />
</template>
