const routes = [
  { path: "/", redirect: "/day" },
  { path: "/settings", redirect: "/settings/account" },
  {
    path: "/settings/integrations/add-outlook-calendar",
    redirect: "/settings/integrations",
  },
  {
    path: "/welcome",
    name: "welcome",
    meta: {
      requiresAuth: false,
      onlyLightTheme: true,
    },
    component: () => import("@/pages/WelcomePage.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      requiresAuth: false,
      onlyLightTheme: true,
    },
    component: () => import("@/pages/LoginPage.vue"),
  },
  {
    path: "/admin-login",
    name: "admin-login",
    meta: {
      requiresAuth: false,
      onlyLightTheme: true,
    },
    component: () => import("@/pages/AdminLoginPage.vue"),
  },
  {
    path: "/unavailable",
    name: "unavailable",
    meta: {
      requiresAuth: false,
      onlyLightTheme: true,
    },
    component: () => import("@/pages/Unavailable.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    meta: {
      requiresAuth: false,
      onlyLightTheme: true,
    },
    component: () => import("@/pages/RegistrationPage.vue"),
    props: true,
  },
  {
    path: "/migration",
    name: "migration",
    meta: {
      requiresAuth: false,
      onlyLightTheme: true,
    },
    component: () => import("@/pages/MigrationPage.vue"),
    props: true,
  },
  {
    path: "/objectives",
    name: "objectives",
    meta: { requiresAuth: true },
    component: () => import("@/pages/ObjectivesPage.vue"),
  },
  {
    path: "/day",
    name: "day",
    meta: { requiresAuth: true },
    component: () => import("@/pages/DayPage.vue"),
  },
  {
    path: "/week",
    name: "week",
    meta: { requiresAuth: true },
    component: () => import("@/pages/WeekPage.vue"),
  },
  {
    path: "/weekly-review/:week",
    name: "review",
    meta: { requiresAuth: true },
    component: () => import("@/pages/WeeklyReviewPage.vue"),
  },
  {
    path: "/onboarding",
    name: "onboarding",
    meta: {
      requiresAuth: true,
      onlyLightTheme: true,
    },
    component: () => import("@/pages/OnboardingPage.vue"),
  },
  {
    path: "/onboarding-questions",
    name: "onboarding-questions",
    meta: {
      requiresAuth: true,
      onlyLightTheme: true,
    },
    component: () => import("@/pages/OnboardingQuestionsPage.vue"),
  },
  {
    path: "/onboarding-final",
    name: "onboarding-final",
    meta: {
      requiresAuth: true,
      onlyLightTheme: true,
    },
    component: () => import("@/pages/OnboardingFinalPage.vue"),
  },
  {
    path: "/onboarding-upgrade",
    name: "onboarding-upgrade",
    meta: {
      requiresAuth: true,
      onlyLightTheme: true,
    },
    component: () => import("@/pages/OnboardingUpgradePage.vue"),
  },
  {
    path: "/activities",
    name: "activities",
    meta: { requiresAuth: true },
    component: () => import("@/pages/ActivitiesPage.vue"),
  },
  {
    path: "/activity/:id",
    name: "activity",
    meta: { requiresAuth: true },
    component: () => import("@/pages/activity/ActivityPage.vue"),
    children: [
      {
        path: "",
        name: "activityInfo",
        meta: { requiresAuth: true },
        component: () => import("@/pages/activity/ActivityInfoPage.vue"),
      },
      {
        path: "explore-data",
        name: "activityExploreData",
        meta: { requiresAuth: true },
        component: () => import("@/pages/activity/ActivityExploreDataPage.vue"),
      },
      {
        path: "linked-objectives",
        name: "activityLinkedObjectives",
        meta: { requiresAuth: true },
        component: () =>
          import("@/pages/activity/ActivityLinkedObjectivesPage.vue"),
      },
    ],
  },
  {
    path: "/upgrade",
    name: "upgrade",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/UpgradePage.vue"),
  },
  {
    path: "/get-started",
    name: "get-started",
    meta: { requiresAuth: true },
    component: () => import("@/pages/GetStartedPage.vue"),
  },
  {
    path: "/premium-success",
    name: "premium-success",
    meta: { requiresAuth: true },
    component: () => import("@/pages/PremiumSuccessPage.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: { requiresAuth: true },
    component: () => import("@/pages/settings/SettingsPage.vue"),
    children: [
      {
        path: "",
        name: "settings",
        meta: { requiresAuth: true },
        component: () => import("@/pages/settings/SettingsPage.vue"),
      },
      {
        path: "account",
        name: "account-settings",
        meta: { requiresAuth: true },
        component: () => import("@/pages/settings/AccountSettings.vue"),
      },
      {
        path: "general",
        name: "general-settings",
        meta: { requiresAuth: true },
        component: () => import("@/pages/settings/GeneralSettings.vue"),
      },
      {
        path: "integrations",
        name: "integrations-settings",
        meta: { requiresAuth: true },
        component: () =>
          import("@/pages/settings/integrations/IntegrationsSettings.vue"),
        children: [
          {
            path: ":id",
            name: "integration/google-calendar",
            meta: { requiresAuth: true },
            component: () =>
              import(
                "@/pages/settings/integrations/GoogleCalendarIntegrationPage.vue"
              ),
          },
        ],
      },
      {
        path: "subscription",
        name: "subscription-settings",
        meta: { requiresAuth: true },
        component: () => import("@/pages/settings/SubscriptionSettings.vue"),
      },
      {
        path: "feedback",
        name: "feedback-settings",
        meta: { requiresAuth: true },
        component: () => import("@/pages/settings/FeedbackSettings.vue"),
      },
      {
        path: "danger-zone",
        name: "danger-zone-settings",
        meta: { requiresAuth: true },
        component: () => import("@/pages/settings/DangerZoneSettings.vue"),
      },
    ],
  },
];

export default routes;
