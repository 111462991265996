import createClient, { Middleware } from "openapi-fetch";
import type { paths } from "@/lib/api/v1"; // generated by openapi-typescript
import { remoteHost } from "@/api/remote";
import { MgwApiError, MgwError } from "./apiExceptions";

const api = createClient<paths>({ baseUrl: remoteHost });

const myMiddleware: Middleware = {
  async onRequest({ request }) {
    return request;
  },
  async onResponse({ response }) {
    // For some reasone without such middleware, some requests are not deserialized correctly on Android, providing empty response pbject {}.
    // The reason seems to be content-length: 0 in the response headers only for HTTPS from remote server, but not for local server with HTTP.
    const { body, ...resOptions } = response;

    return new Response(body, { ...resOptions });
  },
};
api.use(myMiddleware);

export function getApiError(error?: MgwError) {
  if (error) {
    return new MgwApiError(error);
  }
  return new MgwApiError({ code: 0, message: "Unknown error" });
}

export default api;
