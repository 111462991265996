export interface MgwError {
  code: number;
  message: string;
}

export class MgwApiError extends Error {
  code: number;
  constructor(mgwError: MgwError) {
    super(mgwError.message);
    this.code = mgwError.code;
  }
}
