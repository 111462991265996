<script setup lang="ts">
import { computed } from "vue";
import Toast from "./Toast.vue";

interface ToastData {
  id: string;
  title: string;
  message: string;
  lifeTime: number;
  severity: "SUCCESS" | "WARNING" | "ERROR" | "INFO";
}

const props = defineProps({
  toasts: { type: Array as () => Array<ToastData>, required: true },
});

const emit = defineEmits<{
  (e: "removeToast", id: string): void;
}>();

const list = computed(() => props.toasts);
</script>

<template>
  <div
    class="toast-container absolute right-4 z-[2000] space-y-2 max-md:right-0 max-md:top-4 max-md:w-full max-md:px-4 md:bottom-4"
  >
    <template v-for="toast in list" :key="toast.id">
      <Toast
        :id="toast.id"
        :title="toast.title"
        :message="toast.message"
        :lifeTime="toast.lifeTime"
        :severity="toast.severity"
        @removeToast="emit('removeToast', $event)"
      />
    </template>
  </div>
</template>

<style>
.toast-enter-active {
  animation: toast-enter-active 250ms forwards;
}

.toast-leave-active {
  animation: toast-leave-active 250ms forwards;
}

@keyframes toast-enter-active {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes toast-leave-active {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.toast-container {
  margin-top: var(--safe-area-inset-top);
  margin-bottom: var(--safe-area-inset-bottom);
}
</style>
