import { RouteLocationNormalized } from "vue-router";
import * as trackingApi from "@/api/tracking/trackingApi";
import { Capacitor } from "@capacitor/core";
import { TrackingPlatform } from "@/types/tracking";

export async function trackPageView(to: RouteLocationNormalized) {
  const page = to.name?.toString() || "unknown";
  const url = to.fullPath;

  let platform: TrackingPlatform = "WEB";
  switch (Capacitor.getPlatform()) {
    case "android":
      platform = "ANDROID";
      break;
    case "ios":
      platform = "IOS";
      break;
    default:
      platform = "WEB";
      break;
  }

  trackingApi.trackPageView(page, url, platform);
}
