<script setup lang="ts">
import { onMounted, ref } from "vue";

const props = defineProps<{
  id: string;
  title: string;
  message: string;
  lifeTime: number;
  severity: "SUCCESS" | "WARNING" | "ERROR" | "INFO";
}>();

const emit = defineEmits<{
  (e: "removeToast", event: string): void;
}>();

const isVisible = ref<boolean>(false);

const removeToast = () => {
  isVisible.value = false;
  setTimeout(() => {
    emit("removeToast", props.id);
  }, 250);
};

onMounted(() => {
  isVisible.value = true;

  setTimeout(() => {
    removeToast();
  }, props.lifeTime);
});
</script>

<template>
  <Transition name="toast">
    <div
      v-if="isVisible"
      class="relative min-h-[60px] w-[398px] rounded-md border-[0.5px] p-2 backdrop-blur-md max-md:w-full"
      :class="{
        'toast-success': severity === 'SUCCESS',
        'toast-warning': severity === 'WARNING',
        'toast-error': severity === 'ERROR',
        'toast-info': severity === 'INFO',
      }"
    >
      <div class="flex flex-col gap-2">
        <div class="toast-icon flex items-center gap-2">
          <i
            class="pi"
            :class="{
              'pi-check': severity === 'SUCCESS',
              'pi-exclamation-triangle': severity === 'WARNING',
              'pi-times-circle': severity === 'ERROR',
              'pi-info-circle': severity === 'INFO',
            }"
          ></i>
          <div class="font-medium">{{ title }}</div>
        </div>

        <div class="text-sm">{{ message }}</div>
      </div>

      <button
        class="toast-close absolute right-2 top-2 grid h-7 w-7 place-items-center rounded-full"
        @click="removeToast"
      >
        <i class="pi pi-times"></i>
      </button>
    </div>
  </Transition>
</template>

<style scoped>
.toast-success {
  @apply border-green-200 bg-green-100/70 dark:border-green-600 dark:bg-green-900/60;
}
.toast-success .toast-icon {
  @apply text-green-600;
}
.toast-success .toast-close {
  @apply text-green-600 hover:bg-green-100 dark:hover:bg-green-900;
}

.toast-warning {
  @apply border-yellow-200 bg-yellow-50/70 dark:border-yellow-600 dark:bg-yellow-900/60;
}
.toast-warning .toast-icon {
  @apply text-yellow-600;
}
.toast-warning .toast-close {
  @apply text-yellow-600 hover:bg-yellow-100 dark:hover:bg-yellow-900;
}

.toast-error {
  @apply border-red-200 bg-red-50/70 dark:border-red-600 dark:bg-red-900/60;
}
.toast-error .toast-icon {
  @apply text-red-600;
}
.toast-error .toast-close {
  @apply text-red-600 hover:bg-red-100 dark:hover:bg-red-900;
}

.toast-info {
  @apply border-blue-200 bg-blue-50/70 dark:border-blue-600 dark:bg-blue-900/60;
}
.toast-info .toast-icon {
  @apply text-blue-600;
}
.toast-info .toast-close {
  @apply text-blue-600 hover:bg-blue-100 dark:hover:bg-blue-900;
}
</style>
