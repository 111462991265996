import api, { getApiError } from "@/api";
import { OnboardingStep } from "@/types/common";

export async function getUser() {
  const { data, error } = await api.GET("/api/v1/user");
  if (error || !data) {
    throw getApiError(error);
  }
  return data;
}

export interface UpdateUserRequest {
  name?: string;
}

export async function updateUser(request: UpdateUserRequest) {
  const { data, error } = await api.PATCH("/api/v1/user", {
    body: request,
  });
  if (error || !data) {
    throw getApiError(error);
  }
  return data;
}

export async function completeOnboardingStep(step: OnboardingStep) {
  const { error } = await api.POST("/api/v1/user/onboarding-step/complete", {
    body: {
      step,
    },
  });
  if (error) {
    throw getApiError(error);
  }
}
