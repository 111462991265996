import { v4 as uuidv4 } from "uuid";
import { createVNode, getCurrentInstance, render, VNode, ref } from "vue";
import ToastContainer from "@/components/ui/toasts/ToastContainer.vue";
import type { ToastSeverity } from "@/types/common";
import { useI18n } from "vue-i18n";

const toastsContainer = ref<HTMLElement | null>(null);
const toastList = ref<
  {
    id: string;
    severity: ToastSeverity;
    title: string;
    message: string;
    lifeTime: number;
  }[]
>([]);

export default function useToasts() {
  const { t } = useI18n();

  addToastsContainerToDOM();
  function addToastsContainerToDOM() {
    if (!toastsContainer.value) {
      toastsContainer.value = document.createElement("div");
      toastsContainer.value.id = "toasts-container";
      document.body.appendChild(toastsContainer.value);
      const vnode: VNode = createVNode(ToastContainer, {
        toasts: toastList.value,
        onRemoveToast: (toastId: string) => {
          removeToast(toastId);
        },
      });
      const instance = getCurrentInstance();
      if (instance) {
        vnode.appContext = instance.appContext;
      }

      render(vnode, toastsContainer.value);
    }
  }

  const removeToast = (toastId: string) => {
    // remove toast from list
    const index = toastList.value.findIndex((toast) => toast.id === toastId);
    if (index !== -1) {
      toastList.value.splice(index, 1);
    }
  };

  const addToast = (
    severity: ToastSeverity,
    title: string,
    message: string,
    lifeTime: number = 3000,
  ) => {
    const toastId = uuidv4();
    toastList.value.push({ id: toastId, severity, title, message, lifeTime });
  };

  const addToastSuccess = (message: string, lifeTime: number = 3000) => {
    const toastId = uuidv4();
    toastList.value.push({
      id: toastId,
      severity: "SUCCESS",
      title: t("toasts.success"),
      message,
      lifeTime,
    });
  };

  const addToastError = (message?: string, lifeTime: number = 3000) => {
    const toastId = uuidv4();
    toastList.value.push({
      id: toastId,
      severity: "ERROR",
      title: t("toasts.error"),
      message: message || t("common.errors.unexpected"),
      lifeTime,
    });
  };

  const addToastWarning = (message: string, lifeTime: number = 3000) => {
    const toastId = uuidv4();
    toastList.value.push({
      id: toastId,
      severity: "WARNING",
      title: t("toasts.warning"),
      message,
      lifeTime,
    });
  };

  const addToastInfo = (message: string, lifeTime: number = 3000) => {
    const toastId = uuidv4();
    toastList.value.push({
      id: toastId,
      severity: "INFO",
      title: t("toasts.info"),
      message,
      lifeTime,
    });
  };

  return {
    addToast,
    addToastSuccess,
    addToastWarning,
    addToastError,
    addToastInfo,
    removeToast,
    toastList,
  };
}
