import { createApp } from "vue";
import { createPinia } from "pinia";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import "./style.css";
import App from "@/App.vue";
import PrimeVue from "primevue/config";
import MyTheme from "@/myTheme";
import "primeicons/primeicons.css";
import i18n from "@/i18n";
import "@capacitor-community/safe-area";
import { Capacitor } from "@capacitor/core";
import router from "@/router";
import * as Sentry from "@sentry/vue";

const pinia = createPinia();
const app = createApp(App);

if (process.env.NODE_ENV === "production" && !Capacitor.isNativePlatform()) {
  Sentry.init({
    app,
    dsn: "https://78836dd017cca253b8156270f9622839@o4508075298455552.ingest.us.sentry.io/4508075301011456",
    integrations: [
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error", "warn"],
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// https://primevue.org/tooltip/
app.directive("tooltip", Tooltip);

app
  .use(router)
  .use(i18n)
  .use(PrimeVue, {
    theme: MyTheme,
  })
  .use(ToastService)
  .use(pinia)
  .mount("#app");
