import { Capacitor } from "@capacitor/core";

export let remoteHost = "/";

if (Capacitor.isNativePlatform()) {
  switch (process.env.NODE_ENV) {
    case "production":
      remoteHost = "https://app.mygoodweek.com/";
      break;
    default:
      if (Capacitor.getPlatform() === "android") {
        remoteHost = "https://mgw2.skkap.com/";
        //remoteHost = "http://10.0.2.2:8081/";
      } else if (Capacitor.getPlatform() === "ios") {
        remoteHost = "https://mgw2.skkap.com/";
        //remoteHost = "http://localhost:8081/";
      }
  }
}
