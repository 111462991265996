import api, { getApiError } from "@/api";
import { TrackingPlatform } from "@/types/tracking";

export async function trackPageView(
  page: string,
  url: string,
  platform: TrackingPlatform,
) {
  const { data, error } = await api.GET("/api/v1/t/pv", {
    params: {
      query: {
        p: page,
        u: url,
        pl: platform,
      },
    },
  });
  if (error || !data) {
    throw getApiError(error);
  }
  return data;
}
