import { createI18n } from "vue-i18n";
import * as enLocale from "@/locales/en.json";
import * as esLocale from "@/locales/es.json";

const messages = { en: enLocale, es: esLocale };

export default createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages,
});
