import { defineStore } from "pinia";
import { useAuthStore } from "@/store/auth/authStore";
import * as serverConfigApi from "@/api/serverConfigApi";
import { ref, computed } from "vue";
import useInitializableStore from "@/store/initializableStore";
import { AppConfig } from "./config";

export const useAppStore = defineStore("app", () => {
  const { initializationFailed, initialized, loading, baseInitialize } =
    useInitializableStore();

  const config = ref<AppConfig>();

  async function initialize(force = false): Promise<void> {
    return baseInitialize(internalInit, force);
  }

  async function internalInit() {
    const severConfigResponse = await serverConfigApi.getServerConfig();

    config.value = severConfigResponse;

    const authStore = useAuthStore();
    await authStore.initialize();
  }

  const appConfig = computed(() => {
    if (!config.value) {
      throw new Error("App config not initialized");
    }
    return config.value;
  });

  return {
    initializationFailed,
    initialized,
    loading,
    appConfig,
    initialize,
  };
});
